import React, {Component} from "react";
import {Input} from "@material-ui/core";

import './PopmasterPackage.css';

class PopmasterPackage extends Component {

    determinePackage = () => {
        let props = this.props;
        let eventType = props.eventType === 'eventBirthday' ? 'eventOther' : props.eventType;
        return props.packages[eventType][props.persons];
    };

    render() {
        let thePackage = this.determinePackage();

        let classes = 'thepackage package-' + this.props.persons;
        let packageValue = 'paketti-' + this.props.persons + '-' + this.props.eventType + '-' + this.props.eventProgram;
        let title = thePackage.title;
        let description = thePackage.description;
        let extraHours = this.props.extraHours;

        return (
            <div className={classes}>
                <h4 className={'title'}>{title}</h4>
                {description.length &&
                    <div>{description}</div>
                }
                {extraHours &&
                <div className={'extra-hours'}>Lisätunnit: {extraHours}h</div>
                }
                <Input type='hidden' id='package' name='package' value={packageValue} className={'hidden'}/>
            </div>
        );
    };
}

export default PopmasterPackage;