import React, {Component} from "react";
import {TableBody} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import moment from "moment";

class PopmasterEventSummaryMini extends Component {

    render() {
        let data = this.props.data;
        let rateName = () => {
            let dow = moment(data.eventDate).day();
            if (dow === 0) {
                return 'sunday';
            } else if(dow === 5 || dow === 6) {
                return 'weekend';
            } else {
                return 'week';
            }
        };

        let p = data.thePackage || false;
        let hourlyRate = data.data.prices.rates[rateName()];
        let thePackagePrice;

        if (p !== false) {
            thePackagePrice = p.basePrice;
        }

        let date = moment(data.eventDate);

        return (
            <div className={'event-summary'}>
                <h3>Tapahtuman tiedot</h3>
                <Table size={'small'}>
                    <TableBody>
                        {data.currentEvent.title &&
                        <TableRow>
                            <TableCell>Tapahtuma</TableCell><TableCell>{data.currentEvent.title}</TableCell>
                        </TableRow>}
                        {data.eventProgram &&
                        <TableRow>
                            <TableCell>Ohjelma</TableCell><TableCell>{data.eventProgram}</TableCell>
                        </TableRow>}
                        {data.eventPersons &&
                        <TableRow>
                            <TableCell>Henkilömäärä</TableCell><TableCell>{data.eventPersons}</TableCell>
                        </TableRow>}
                        {data.eventLocation &&
                        <TableRow>
                            <TableCell>Tapahtumapaikka</TableCell><TableCell>{data.eventLocation}</TableCell>
                        </TableRow>}
                        {data.eventDate &&
                        <TableRow>
                            <TableCell>Päivämäärä</TableCell><TableCell>{date.format('DD.MM.YYYY')}</TableCell>
                        </TableRow>}
                        {data.eventStartTime &&
                        <TableRow>
                            <TableCell>Kello</TableCell>
                            <TableCell>{data.eventStartTime} - {data.eventEndTime}</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                <h3>Kustannukset</h3>
                <Table size={'small'}>
                    <TableBody>
                        {p &&
                        <TableRow>
                            <TableCell>{p.title}</TableCell>
                            <TableCell>{thePackagePrice}€</TableCell>
                        </TableRow>}
                        {data.transportCost &&
                        <TableRow>
                            <TableCell>Matkakorvaus</TableCell>
                            <TableCell>{data.transportCost}€ {Math.round(data.route.distance / 1000)}km ({data.route.origin})</TableCell>
                        </TableRow>}
                        {data.isSunday &&
                        <TableRow>
                            <TableCell>Sunnuntailisä</TableCell>
                            <TableCell>{data.sundayExtraCost}€</TableCell>
                        </TableRow>}
                        {data.extraHours &&
                        <TableRow>
                            <TableCell>Lisätunnit {data.extraHours} * {hourlyRate}€</TableCell>
                            <TableCell>{data.extraHoursCost}€</TableCell>
                        </TableRow>}
                        {data.difficultToCarry &&
                        <TableRow>
                            <TableCell>Erityskohdelisä</TableCell>
                            <TableCell>{data.data.prices.rates.carryAid}€</TableCell>
                        </TableRow>}
                        {data.preRigging &&
                        <TableRow>
                            <TableCell>Kasaus erikseen {data.data.prices.rates.assemblyPrice}€ + Matkakorvaus: {data.transportCost}€</TableCell>
                            <TableCell>{data.data.prices.rates.assemblyPrice + data.transportCost}€</TableCell>
                        </TableRow>}
                        {data.laterUnrigging &&
                        <TableRow>
                            <TableCell>Purku erikseen {data.data.prices.rates.assemblyPrice}€ + Matkakorvaus: {data.transportCost}€</TableCell>
                            <TableCell>{data.data.prices.rates.assemblyPrice + data.transportCost}€</TableCell>
                        </TableRow>}

                        {data.additionalGears && data.additionalGears.length > 0 &&
                        data.additionalGears.filter(gear => gear.qty > 0).map((v, i) => {
                            let data = v;
                            return (
                                <TableRow key={i}>
                                    <TableCell>{data.qty} * {data.title} á {data.price}€</TableCell>
                                    <TableCell>{data.price*data.qty}€</TableCell>
                                </TableRow>
                            );
                        })}
                        {data.totalCost &&
                        <TableRow>
                            <TableCell><b>Kustannusarvio</b></TableCell>
                            <TableCell><b>{data.totalCost}€</b></TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
        );
    };
}

export default PopmasterEventSummaryMini;