import React, {Component} from 'react';
import './Tile.css';

class Tile extends Component {

    handleClick = () => {
        this.props.callback(this.props.id);
    };

    render() {
        let classes = this.props.id.toLowerCase() + ' tile';
        return (
            <div className={classes} id={this.props.id} onClick={this.handleClick}>
                <span>{this.props.title}</span>
            </div>
        )
    }
}

export default Tile;
