import React, { Component } from 'react';

// CSS
import './App.css';
import './ModalClose.css';

// Data
import Packages from './data/packages.json';
import Events from './data/events.json';
import Prices from './data/prices.json';

// Components
import Tile from './Tile.js';
import ButtonNavigation from './ButtonNavigation.js';
import Section from './Section.js';
import EventLocation from './EventLocation';
import PopmasterPackage from './PopmasterPackage';
import PopmasterAdditionalGear from './PopmasterAdditionalGear';
import PopmasterClientSummary from './PopmasterClientSummary';
import PopmasterEventSummary from './PopmasterEventSummary';
import PopmasterCostBreakdown from "./PopmasterCostBreakdown";

// MUI
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import {Paper} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";

// Utils
import * as EmailValidator from 'email-validator';
import moment from 'moment';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class App extends Component {

  targetElement = null;

  constructor(props) {
    super(props);
    this.state = {
      data: {
        packages: Packages,
        gears: {},
        events: Events,
        prices: Prices
      },
      additionalGear: [],
      eventData: null,
      eventStartTime: null,
      eventEndTime: null,
      eventType: 'init',
      eventPersons: 30,
      eventProgram: 'DJ',
      laterUnrigging: false,
      difficultToCarry: false,
      preRigging: false,
      extraHours: false,
      isSunday: false,
      hasKaraoke: false,
      callback: {
        saveEventType: this.saveEventTypeCb,
        navigate: this.navigateCb,
        saveEventLocation: this.saveEventLocation,
        saveAdditionalGear: this.saveAdditionalGear
      },
      currentStep: 0,
      currentEvent: {
        title: ''
      },
      modalOpen: false,
      route: {},
      emailValid: true,
      extraHoursCost: false,
      transportCost: false,
      sundayExtraCost: false,
      totalCost: null
    };

    this.mapsKey = 'AIzaSyBnaURBfdeUH__s5FEGSYK6njzc6OlTX2Q';
    this.fetchGears();
  }

  componentWillUnmount() {
    console.log('closed, clear locks');
    clearAllBodyScrollLocks();
  }


  // Init things here
  componentDidMount() {
    this.setBgImages();
    this.initMaps();
    this.determinePackage();
    this.targetElement = document.getElementById('theModal');
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.eventProgram !== prevState.eventProgram) {
      this.determinePackage();
      setTimeout(this.determinePrice, 100);

      if (this.isCorporateKaraoke()) {
        let ag = [...this.state.additionalGears];
        let idx = ag.findIndex((gear => gear.slug === '42-lisanaytto'));
        ag[idx]['qty'] = 1;
        this.setState({additionalGears: ag});
      }
    }

    if (this.state.currentEvent !== prevState.currentEvent) {
      this.determinePackage();
      setTimeout(this.determinePrice, 100);
    }

    if (this.state.eventPersons !== prevState.eventPersons) {
      this.determinePackage();
      setTimeout(this.determinePrice, 100);
    }

    if (this.state.additionalGear !== prevState.additionalGear) {
      this.determinePrice();
    }

    if (prevState.route !== this.state.route) {
      this.calculateTransportCost();
    }

    if (prevState.transportCost !== this.state.transportCost) {
      this.determinePrice();
    }

    if (prevState.isSunday !== this.state.isSunday) {
      this.determinePrice();
    }

    if (prevState.extraHoursCost !== this.state.extraHoursCost) {
      this.determinePrice();
    }

    if (prevState.laterUnrigging !== this.state.laterUnrigging) {
      this.determinePrice();
    }

    if (prevState.preRigging !== this.state.preRigging) {
      this.determinePrice();
    }

    if (prevState.difficultToCarry !== this.state.difficultToCarry) {
      this.determinePrice();
    }

    if (prevState.additionalGears !== this.state.additionalGears) {
      this.determinePrice();
    }



    if (prevState.thePackage && prevState.thePackage !== this.state.thePackage) {
      //console.log('p', prevState.thePackage);
      //console.log('p', this.state.thePackage);
      this.updateAdditionalGears();
    }

  }

  updateAdditionalGears = () => {

    // Map all gears and package.contents
    let merged = [];
    let contents = this.state.thePackage.contents;

    this.state.availableGears.forEach((gear) => {
      if (contents.hasOwnProperty(gear.slug)) {
        merged.push({
          title: gear.title.rendered,
          slug: gear.slug,
          price: gear.price,
          qty: contents[gear.slug]
        });
      } else {
        merged.push({
          title: gear.title.rendered,
          slug: gear.slug,
          price: gear.price,
          qty: 0
        });
      }
    });

    this.setState({additionalGears: merged});

  };

  fetchGears = () => {
    // Todo: change this url when WP theme has been deployed

    //let wl = window.location.host;
    //let isProduction = (!(wl.includes('pm2') || wl.includes('localhost')));


    //let url =
    // TODO: change to HTTPS when cert installed
    //let url = (isProduction) ?
    //    'https://www.popmaster.fi/wp-json/wp/v2/laite?per_page=100' :
    //    'https://pm2.restate.fi/wp-json/wp/v2/laite?per_page=100';
    let url = 'https://www.popmaster.fi/wp-json/wp/v2/laite?per_page=100';

    fetch(url, {mode: 'cors'})
        .then(results => {
          return results.json();
        })
        .then(data => {
          let res = data.filter((gear) => gear.price > 0);
          //console.log('res', res);
          this.setState({availableGears: res});
          //this.props.saveAvailableGears(res);
        });
  };

  calculateTransportCost = () => {
    let cost;
    let totalDistance = this.state.route.distance / 1000 * 2;
    cost = totalDistance * this.state.data.prices.rates.transportCost;
    cost = Math.round(cost);
    //console.log('transport', cost);

    this.setState({transportCost: cost});

  };

  determinePrice = () => {

    let totalCost = 0;

    if (this.state.thePackage) {

      let basePrice;
      /*
      if (this.state.hasKaraoke && this.state.eventType === 'eventCorporate' &&
          (this.state.eventPersons === '50-100' || this.state.eventPersons === '100+')
      ) {
        basePrice = this.state.thePackage.basePrice;
      } else {
        basePrice = this.state.thePackage.basePrice;
      }
       */

      basePrice = this.state.thePackage.basePrice;

      if (this.state.isSunday) {
        totalCost += Number(basePrice * 1.3);
      } else {
        totalCost += Number(basePrice);
      }
    }

    /*
    if (this.state.additionalGear.length > 0) {
      let arr = this.state.additionalGear;
      for(let i=0; i<arr.length; i++) {
        totalCost += Number(arr[i]['price']);
      }
    }
     */

    if (this.state.additionalGears && this.state.additionalGears.length > 0) {
      let ag = this.state.additionalGears;
      for(let i=0; i<ag.length; i++) {
        totalCost += Number(ag[i]['price'] * ag[i]['qty']);
      }
    }

    if (this.state.transportCost > 0) {
      totalCost += Number(this.state.transportCost);
    }

    if (this.state.extraHoursCost > 0) {
      totalCost += Number(this.state.extraHoursCost);
    }

    if (this.state.preRigging) {
      // Add transport cost + assembly cost
      let assemblyCost = Number(this.state.data.prices.rates.assemblyPrice + this.state.transportCost);
      totalCost += assemblyCost;
    }

    if (this.state.laterUnrigging) {
      // Add transport cost + assembly cost
      let assemblyCost = Number(this.state.data.prices.rates.assemblyPrice + this.state.transportCost);
      totalCost += assemblyCost;
    }

    if (this.state.difficultToCarry) {
      let cost = Number(this.state.data.prices.rates.carryAid);
      totalCost += cost;
    }
    this.setState({totalCost: totalCost});
  };

  determinePackage = () => {
    let eventType = this.state.eventType === 'eventBirthday' ? 'eventOther' : this.state.eventType;
    let thePackage = this.state.data.packages[eventType][this.state.eventPersons];
    this.setState({thePackage: thePackage});
  };

  /*
  saveAdditionalGear = (gearsArr) => {
    //console.log('gearsArr to state', gearsArr);
    let arr = [];
    for(let i=0; i<gearsArr.length; i++) {
      if (typeof gearsArr[i] === 'object') {
        arr[i] = gearsArr[i];
      } else {
        arr[i] = JSON.parse(gearsArr[i]);
      }
    }
    this.setState({additionalGear: arr});
  };
   */

  saveAdditionalGear = (gearsObj) => {
    this.setState({additionalGear: gearsObj});
  };

  saveEventLocation = (data) => {
    this.setState(data);
  };

  nextAvailableDate = () => {
    return moment().add(3, 'days');
  };

  initMaps = () => {
    // Gmaps distance matrix service
    let gmapsUrl = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=' + this.mapsKey;
    let script = document.createElement('script');
    script.src = gmapsUrl;
    script.async = true;
    document.body.appendChild(script);
  };

  setBgImages = () => {
    let events = [...this.state.data.events];
    this.setState({events: events});
  };

  getEventByType = (type) => {
    let events = [...this.state.events];
    return events.find(obj => {
      return obj.id === type
    });
  };

  saveEventTypeCb = (data) => {
    this.setState({currentEvent: this.getEventByType(data)});
    this.setState({eventType: data});
    this.setState({currentStep: 1});
    this.showTile(1);
    //document.getElementById('app').classList.add(data);
  };

  // @todo: google analytics
  sendPage = (step) => {
    //ga('send', 'pageview', [step]);
  };


  navigateCb = (step) => {
    this.setState({currentStep: step});
    this.showTile(step);
  };

  showTile = (tileNro) => {
    let slides = document.getElementsByClassName('slide');
    let toShow = document.getElementById('slide' + tileNro);

    for(let i=0; i<slides.length; i++) {
      slides[i].classList.add('hidden');
    }

    toShow.classList.remove('hidden');
    //console.log('toshow',toShow);
    this.scrollToTop();

  };

  scrollToTop = () => {
    setTimeout(() => {
      let modal = document.getElementById('theModal');
      modal.scrollTo(0,0);
    }, 150);

  };

  showTileCb = (event) => {
    let parent = event.currentTarget;
    let slides = document.getElementsByClassName('slide');
    let targetId = parent.dataset.targetslide;
    let toShow = document.getElementById('slide' + targetId);
    for(let i=0; i<slides.length; i++) {
      slides[i].classList.add('hidden');
    }
    toShow.classList.remove('hidden');
    this.setState({currentStep: targetId});
    this.scrollToTop();
  };

  setOpen = (open) => {
    this.setState({modalOpen: open});
    setTimeout(()=> {
      document.getElementById('theModal').scroll(0,0);

      // Try to lock body scrolling
      if (!window.location.host.includes('localhost')) {
        let page = document.getElementsByTagName('body')[0];
        if(open) {
          //enableBodyScroll(this.targetElement);
          page.classList.add('lock');
        } else {
          //disableBodyScroll(this.targetElement);
          page.classList.remove('lock');
        }
      }

      let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
          navigator.userAgent &&
          navigator.userAgent.indexOf('CriOS') === -1 &&
          navigator.userAgent.indexOf('FxiOS') === -1;

      if(isSafari && window.navigator.platform === 'MacIntel') {
        alert('Popmasterin laskuri ei toimi optimaalisesti Safari-selaimella. Suosittelemme käyttämään selaimena Google Chromea tai Firefoxia.');
      }

    }, 100);
  };

  closeModal = () => {
    this.setOpen(false);
  };

  handleOpenModal = () => {
    this.setOpen(true);
  };

  validateEmail = (event) => {
    if(EmailValidator.validate(event.target.value)) {
      this.setState({emailValid: true});
      this.setState({clientEmail: event.target.value});
      return true;
    }
    else {
      this.setState({emailValid: false});
      return false;
    }
  };

  isCorporateKaraoke = () => {
    return this.state.hasKaraoke && this.state.eventType === 'eventCorporate' &&
        (this.state.eventPersons === '50-100' || this.state.eventPersons === '100+');
  };

  validateDate = (event) => {
    // Todo: more €'s for Sunday
    let dayOfTheWeek = moment(event.target.value + ' 00:00').day();
    let isSunday = dayOfTheWeek === 0;
    this.setState({isSunday: isSunday});
    this.setState({dayOfTheWeek: dayOfTheWeek});
    this.setState({eventDate: event.target.value});

    // add sunday extra
    if (isSunday) {
      let basePrice = (this.isCorporateKaraoke()) ? this.state.thePackage.priceWithKaraoke : this.state.thePackage.basePrice;
      let extraPrice = Math.round((basePrice/100)*30);
      this.setState({sundayExtraCost: extraPrice});
    }
  };

  validateForm = (event) => {

    // Need to have at least name, email and mobile
    if (!this.state.emailValid || !this.state.clientName || !this.state.clientMobile) {
      alert('Ole hyvä tarkista antamasi yhteystiedot.');
      return false;
    }

    event.target.disabled = true;

    //let url = 'http://localhost:3000';
    const url = window.ajaxurl;
    const wpNonce = window.nonce;
    //console.log('nonce+url', wpNonce, url);

    let slide = document.getElementById('slide4');
    let clientSummary = slide.getElementsByClassName('client-summary');
    let eventSummary = slide.getElementsByClassName('event-summary');
    let summary = clientSummary[0].outerHTML + eventSummary[0].outerHTML;

    let payload = {
      _wpnonce: wpNonce,
      action: 'handle_form',
      clientEmail: this.state.clientEmail,
      clientName: this.state.clientName,
      clientMobile: this.state.clientMobile,
      clientInfo: this.state.clientInfo,
      eventType: this.state.eventType,
      eventStartTime: this.state.eventStartTime,
      eventEndTime: this.state.eventEndTime,
      eventDate: this.state.eventDate,
      eventPersons: this.state.eventPersons,
      eventProgram: this.state.eventProgram,
      eventLocation: this.state.eventLocation,
      extraHours: this.state.extraHours,
      extraHoursCost: this.state.extraHoursCost,
      laterUnrigging: this.state.laterUnrigging,
      preRigging: this.state.preRigging,
      sundayExtraCost: this.state.sundayExtraCost,
      thePackage: this.state.thePackage,
      route: this.state.route,
      additionalGear: this.state.additionalGear,
      totalCost: this.state.totalCost,
      summary: summary
    };

    let $ = window.jQuery;

    $.post(url, payload, (res) => {
      console.log('res', res);
      alert(res.message);
      if (res.success) {
        this.setState({modalOpen: false});
        window.location.reload(true);
      }
    });
  };

  validateTime = (event) => {
    //console.log('validateTime', event.target);
    let obj = {};
    const defaultDuration = 6;

    let dateTimeForRounding = moment(document.getElementById('eventDate').value + ' ' + event.target.value);
    (dateTimeForRounding.minute() >= 30) ? dateTimeForRounding.minute(30) : dateTimeForRounding.minute(0);
    document.getElementById(event.target.id).value = dateTimeForRounding.format('HH:mm');

    // Set eventEndTime +6h
    if (event.target.id === 'eventStartTime' && this.state.eventDate && !this.state.eventEndTime) {
      let end = document.getElementById('eventEndTime');
      let date = moment(document.getElementById('eventDate').value + ' ' + event.target.value);

      //console.log('date', date.format('Y-MM-DD HH:mm'));

      let dateTime = moment(date.format('Y-MM-DD HH:mm')).add(defaultDuration, 'hours').format('HH:mm');
      end.value = dateTime;
      //console.log('dateTime', dateTime);
      this.setState({'eventEndTime': dateTime});
    }

    // update when startTime and endTime has been set, and startTime has been changed
    if (event.target.id === 'eventStartTime' && event.target.value !== this.state.eventStartTime && this.state.eventStartTime) {
      let date = moment(document.getElementById('eventDate').value + ' ' + event.target.value);
      event.target.value = moment(date.format('Y-MM-DD HH:mm')).format('HH:mm');
    }

    // Check for over 6h duration
    if (event.target.id === 'eventEndTime' || event.target.id === 'eventStartTime') {
      let startTime = document.getElementById('eventStartTime');
      let endTime = document.getElementById('eventEndTime');
      let day = document.getElementById('eventDate');
      let startDate = day.value + ' ' + startTime.value;
      let endDate = day.value + ' ' + endTime.value;

      let startDateTime = moment(startDate);
      let endDateTime = moment(endDate);
      let duration = moment.duration(endDateTime.diff(startDateTime)).as('hours');

      // If hours go over midnight, the duration is negative and need to add one day to startDateTime
      if (duration < 0) {
        endDateTime.add(1, 'days');
        duration = moment.duration(endDateTime.diff(startDateTime)).as('hours');
      }

      if (duration > defaultDuration) {
        //console.log('duration', duration);
        let extraHours = duration - defaultDuration;
        let extraHoursCost = 0;
        this.setState({extraHours: extraHours});

        let dotw = startDateTime.day();

        // Sunday
        switch (dotw) {
          case 0: // Sunday
            extraHoursCost = extraHours * this.state.data.prices.rates.sunday;
            break;

          case 5:
          case 6:
            extraHoursCost = extraHours * this.state.data.prices.rates.weekend;
            break;

          default:
            extraHoursCost = extraHours * this.state.data.prices.rates.week;
            break;
        }

        this.setState({extraHoursCost: extraHoursCost});

      }
    }
    obj[event.target.id] = event.target.value;
    this.setState(obj);
  };

  saveToState = (event) => {
    let target = event.target;
    if (event.target.type === 'checkbox') {
      let value = !!event.target.checked;
      this.setState({
        [target.id]: value
      });
    } else {
      this.setState({
        [target.id]: target.value
      });
    }

  };

  saveEventProgram = (event) => {
    let target = event.target;
    let hasKaraoke = target.value.toLowerCase().includes("karaoke");
    this.setState({hasKaraoke: hasKaraoke});
    this.setState({eventProgram: target.value});
  };

  handleDeleteGear = chipToDelete => () => {
    let gears = [...this.state.additionalGear];
    gears.splice(chipToDelete, 1);
    this.setState({additionalGear: gears});
  };

  deductCb = (name) => {
    let ag = [...this.state.additionalGears];
    let index = ag.findIndex(gear => gear.slug === name);
    let device = ag.find(dev => dev.slug === name);
    device.qty = (device.qty > 0) ? device.qty - 1 : device.qty;
    ag[index] = device;
    this.setState({additionalGears: ag});
  };

  addCb = (name) => {
    let ag = [...this.state.additionalGears];
    let index = ag.findIndex(gear => gear.slug === name);
    let device = ag.find(dev => dev.slug === name);
    device.qty = (device.qty < 10) ? device.qty + 1 : device.qty;
    ag[index] = device;
    this.setState({additionalGears: ag});
  };


  render() {
    let stateData = this.state.data;
    let cb = this.state.callback.saveEventType;
    let eventLocationCb = this.state.callback.saveEventLocation;
    let navCb = this.state.callback.navigate;
    let modalStyles = {
      'overflow': 'scroll'
    };
    let additionalGear = this.state.additionalGear;

    return (
        <div className={'openModalWrapper'}>
          <button onClick={this.handleOpenModal} id={'openModal'} className={'openModal'}>Laskuriin</button>
          <Modal open={this.state.modalOpen}
          onClose={this.closeModal}
          keepMounted={true}
          id={'theModal'}
          style={modalStyles}>
            <div className="App" id="app">
              <Container maxWidth='md' className='mainContainer'>

                  <ButtonNavigation currentStep={this.state.currentStep} callback={navCb} closeModalCb={this.closeModal} />

                  <Section className="slide" id="slide0">
                    <Container maxWidth='md' className='slide-content'>
                      <Paper className='slide-content-wrap'>
                        <div className="tile-wrap">
                          <header>
                            <h2>Valitse tapahtuman tyyppi</h2>
                          </header>
                          {stateData.events.map((item, key) =>
                              <Tile key={key}
                                    title={item.title}
                                    id={item.id}
                                    callback={cb} />
                          )}
                        </div>
                      </Paper>
                    </Container>
                  </Section>



                  <Section className="slide hidden" id="slide1">
                    <Container maxWidth='md' className='slide-content'>
                      <Paper className='slide-content-wrap'>
                        <h3>Missä ja milloin?</h3>
                        <EventLocation callback={eventLocationCb}/>
                        <FormControl className='formControl'>
                          <TextField id='eventDate'
                                     label='Päivämäärä'
                                     name='eventDate'
                                     type='date'
                                     onBlur={this.validateDate}
                                     InputLabelProps={{ shrink: true }}
                                     inputProps={{'min': this.nextAvailableDate().format('Y-MM-DD')}}
                                     />
                        </FormControl>
                        <FormControl className='formControl'>
                          <TextField id='eventStartTime'
                                     label='Alkamisaika'
                                     name='eventStartTime'
                                     type='time'
                                     inputProps={{
                                       step: 600
                                     }}
                                     onBlur={this.validateTime}
                                     InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                        <FormControl className='formControl'>
                          <TextField id='eventEndTime'
                                     label='Päättymisaika'
                                     name='eventEndTime'
                                     type='time'
                                     inputProps={{
                                       step: 1800
                                     }}
                                     onBlur={this.validateTime}
                                     InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                        <div className={'buttonsWrap'}>
                          <Button onClick={this.showTileCb} data-targetslide='0' variant={'outlined'}>Takaisin</Button>
                          <Button onClick={this.showTileCb} data-targetslide='2' variant={'outlined'} className={'orange'}>Seuraava</Button>
                        </div>
                        <PopmasterCostBreakdown data={this.state} />
                      </Paper>
                    </Container>
                  </Section>



                  <Section className="slide hidden" id="slide2">
                    <Container maxWidth='md' className='slide-content'>
                      <Paper className='slide-content-wrap'>
                        <h3>Ohjelma ja tapahtumapaikka</h3>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <FormControl className={'formControl'}>
                              <InputLabel htmlFor='eventPersons'>Henkilömäärä</InputLabel>
                              <NativeSelect
                                  id={'eventPersons'}
                                  value={this.state.eventPersons}
                                  name='eventPersons'
                                  onChange={this.saveToState}
                                  input={<Input id="eventPersons"/>}
                              >
                                <option value={'30'}>&lt; 30</option>
                                <option value={'30-50'}>30-50</option>
                                <option value={'50-100'}>50-100</option>
                                <option value={'100+'}>&gt; 100</option>
                              </NativeSelect>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl className={'formControl'}>
                              <InputLabel htmlFor='eventProgram'>Ohjelma</InputLabel>
                              <NativeSelect
                                  id={'eventProgram'}
                                  name='eventProgram'
                                  value={this.state.eventProgram}
                                  onChange={this.saveEventProgram}
                                  input={<Input id="eventProgram"/>}
                              >
                                <option value={'DJ'}>DJ</option>
                                <option value={'Karaoke'}>Karaoke</option>
                                <option value={'DJ ja Karaoke'}>DJ ja Karaoke</option>
                              </NativeSelect>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormGroup row>
                              <FormControlLabel
                                  control={<Checkbox value={'Kyllä'} id={'preRigging'} onChange={this.saveToState}/>}
                                  label={'Laitteiden kasaus erikseen sovittavana ajankohtana?'}
                                  name='preRigging'
                              />
                              <FormControlLabel
                                  control={<Checkbox value={'Kyllä'} id={'laterUnrigging'} onChange={this.saveToState}/>}
                                  label={'Laitteiden purku erikseen sovittavana ajankohtana?'}
                                  name='laterUnrigging'
                              />
                              <FormControlLabel
                                  control={<Checkbox value={'Kyllä'} id={'difficultToCarry'} onChange={this.saveToState}/>}
                                  label={'Kantomatka yli 50m tai muualla kuin katutasossa?'}
                                  name='difficultToCarry'
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                        <div className={'buttonsWrap'}>
                          <Button id='prevSlide' onClick={this.showTileCb} data-targetslide='1' variant={'outlined'}>Takaisin</Button>
                          <Button id='nextSlide' onClick={this.showTileCb} data-targetslide='3' variant={'outlined'} className={'orange'}>Seuraava</Button>
                        </div>
                        <PopmasterCostBreakdown data={this.state} />
                      </Paper>
                    </Container>
                  </Section>


                  <Section className="slide hidden" id="slide3">
                    <Container maxWidth='md' className='slide-content'>
                      <Paper className='slide-content-wrap'>
                        <h3>Ehdotus paketista</h3>
                        <p>Suosittelemme tapahtumaasi alla olevaa kokonaisuutta. Voit vapaasti muokata paketin sisältöä.</p>

                        <div className={'the-package-wrap'}>
                          <PopmasterPackage persons={this.state.eventPersons}
                                            eventType={this.state.eventType}
                                            eventProgram={this.state.eventProgram}
                                            packages={this.state.data.packages}
                                            hasKaraoke={this.state.hasKaraoke}
                                            additionalGear={this.state.additionalGear}
                                            extraHours={this.state.extraHours}
                          />

                          {additionalGear.length > 0 && additionalGear.map((gear, index) => {
                            return <Chip
                                className={'chip'}
                                key={index}
                                label={gear.name}
                                variant={'outlined'}
                                onDelete={this.handleDeleteGear(index)}
                            />
                          })}

                        </div>

                        <h4>Lisävarusteet</h4>

                        <PopmasterAdditionalGear
                            callback={this.saveAdditionalGear}
                            gears={this.state.additionalGears}
                            thePackage={this.state.thePackage}
                            availableGears={this.state.availableGears}
                            deductCb={this.deductCb}
                            addCb={this.addCb}
                        />
                        <div className={'buttonsWrap'}>
                          <Button id='prevSlide' onClick={this.showTileCb} data-targetslide='2' variant={'outlined'}>Takaisin</Button>
                          <Button id='nextSlide' onClick={this.showTileCb} data-targetslide='4' variant={'outlined'} className={'orange'}>Seuraava</Button>
                        </div>
                        <PopmasterCostBreakdown data={this.state} />
                      </Paper>
                    </Container>
                  </Section>


                  <Section className="slide hidden" id="slide4">
                    <Container maxWidth='md' className='slide-content'>
                      <Paper className='slide-content-wrap'>
                        <h3>Yhteenveto</h3>
                        {this.state.clientEmail &&
                          <PopmasterClientSummary data={this.state} />
                        }
                        <PopmasterEventSummary data={this.state} />
                        <div className={'buttonsWrap'}>
                          <Button id='prevSlide' onClick={this.showTileCb} data-targetslide='3' variant={'outlined'}>Takaisin</Button>
                          <Button id='nextSlide' onClick={this.showTileCb} data-targetslide='5' variant={'outlined'} className={'orange'}>Seuraava</Button>
                        </div>
                      </Paper>
                    </Container>
                  </Section>



                  <Section className="slide hidden" id="slide5">
                    <Container maxWidth='md' className='slide-content'>
                      <Paper className='slide-content-wrap'>
                        <h3>Yhteystiedot</h3>
                        <FormControl className='formControl'>
                          <TextField id='clientName' name='clientName' required={true} type='text' label='Nimi' onBlur={this.saveToState} />
                        </FormControl>
                        <FormControl className='formControl'>
                          <TextField id='clientEmail' name='clientEmail' required={true} type='email' label='Sähköposti' onBlur={this.validateEmail} error={!this.state.emailValid} />
                        </FormControl>
                        <FormControl className='formControl'>
                          <TextField id='clientMobile' name='clientMobile' required={true} type='tel' label='Puhelinnumero' onBlur={this.saveToState} />
                        </FormControl>
                        <FormControl className='formControl'>
                          <TextField id='clientInfo' name='clientInfo' type='text' label='Lisätietoja' onBlur={this.saveToState} />
                        </FormControl>
                        <div className={'buttonsWrap'}>
                          <Button id='prevSlide' onClick={this.showTileCb} data-targetslide='4' variant={'outlined'}>Takaisin</Button>

                          <Button id='sendEnquiry' onClick={this.validateForm} className={'orange'} variant={'contained'}>Lähetä</Button>


                        </div>
                        <PopmasterCostBreakdown data={this.state} />
                      </Paper>
                    </Container>
                  </Section>



              </Container>
            </div>
          </Modal>
        </div>
    );
  }
}

export default App;
