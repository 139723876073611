import React, {Component} from "react";

import './ModalClose.css';

class ModalCloseButton extends Component {

    render() {
        let cb = this.props.callback;
        return (
            <div className={'modal-close'}>
                <button id={'modalCloseBtn'} onClick={cb}><i className="fas fa-times" /></button>
            </div>
        );
    };
}

export default ModalCloseButton;