import React, {Component} from "react";
import ModalCloseButton from "./ModalCloseButton";

import './ButtonNavigation.css';

class ButtonNavigation extends Component {

    handleClick = (e, x) => {
        //e.preventDefault();
        //console.log(this.props);
        this.props.callback(this.props.currentStep-1, 'prev');
    };

    render() {
        let curStep = this.props.currentStep;
        let buttons = [
            {
                id: 'btn0',
                target: 'slide0'
            },
            {
                id: 'btn1',
                target: 'slide1'
            },
            {
                id: 'btn2',
                target: 'slide2'
            },
            {
                id: 'btn3',
                target: 'slide3'
            },
            {
                id: 'btn4',
                target: 'slide4'
            },
        ];
        return (
            <div className="buttonNavigation">
                {buttons.map((args, y) => {
                    let dataTargetStr = args.target;
                    let idStr = args.id;
                    let disabled = (curStep <= y);
                    return <button className={'navBtn'} disabled={disabled} onClick={this.handleClick.bind(y)} key={y} data-target={dataTargetStr} id={idStr} ><span>{y+1}</span></button>
                })}
                <ModalCloseButton callback={this.props.closeModalCb} />
            </div>
        )
    };
}

export default ButtonNavigation;