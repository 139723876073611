import React, {Component} from "react";
import {TableBody} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import moment from "moment";

class PopmasterEventSummary extends Component {

    render() {
        let data = this.props.data;
        let rateName = () => {
            let dow = moment(data.eventDate).day();
            if (dow === 0) {
                return 'sunday';
            } else if(dow === 5 || dow === 6) {
                return 'weekend';
            } else {
                return 'week';
            }
        };

        let p = data.thePackage || false;
        let hourlyRate = data.data.prices.rates[rateName()];
        let thePackagePrice;

        if (p !== false) {
            thePackagePrice = p.basePrice;
        }

        let toggle = (event) => {
            const div = document.getElementsByClassName('cost-breakdown-summary');
            for(let i=0; i<div.length; i++) {
                div[i].classList.toggle('hidden');
            }

            const total = document.getElementsByClassName('total');
            for(let i=0; i<total.length; i++) {
                total[i].classList.toggle('hidden');
            }
        };

        return (
            <div className={'event-summary'}>
                <Table size={'small'}>
                    <TableBody>
                        {p &&
                        <TableRow>
                            <TableCell>{p.title}</TableCell>
                            <TableCell>{thePackagePrice}€</TableCell>
                        </TableRow>}
                        {data.transportCost &&
                        <TableRow>
                            <TableCell>Matkakorvaus</TableCell>
                            <TableCell>{data.transportCost}€ {Math.round(data.route.distance / 1000)}km ({data.route.origin})</TableCell>
                        </TableRow>}
                        {data.isSunday &&
                        <TableRow>
                            <TableCell>Sunnuntailisä</TableCell>
                            <TableCell>{data.sundayExtraCost}€</TableCell>
                        </TableRow>}
                        {data.extraHours &&
                        <TableRow>
                            <TableCell>Lisätunnit {data.extraHours} * {hourlyRate}€</TableCell>
                            <TableCell>{data.extraHoursCost}€</TableCell>
                        </TableRow>}
                        {data.difficultToCarry &&
                        <TableRow>
                            <TableCell>Erityskohdelisä</TableCell>
                            <TableCell>{data.data.prices.rates.carryAid}€</TableCell>
                        </TableRow>}
                        {data.preRigging &&
                        <TableRow>
                            <TableCell>Kasaus erikseen {data.data.prices.rates.assemblyPrice}€ + Matkakorvaus: {data.transportCost}€</TableCell>
                            <TableCell>{data.data.prices.rates.assemblyPrice + data.transportCost}€</TableCell>
                        </TableRow>}
                        {data.laterUnrigging &&
                        <TableRow>
                            <TableCell>Purku erikseen {data.data.prices.rates.assemblyPrice}€ + Matkakorvaus: {data.transportCost}€</TableCell>
                            <TableCell>{data.data.prices.rates.assemblyPrice + data.transportCost}€</TableCell>
                        </TableRow>}

                        {data.additionalGears && data.additionalGears.length > 0 &&
                        data.additionalGears.filter(gear => gear.qty > 0).map((v, i) => {
                            let data = v;
                            return (
                                <TableRow key={i}>
                                    <TableCell>{data.qty} * {data.title} á {data.price}€</TableCell>
                                    <TableCell>{data.price*data.qty}€</TableCell>
                                </TableRow>
                            );
                        })}
                        {data.totalCost &&
                        <TableRow>
                            <TableCell><b>Kustannusarvio</b></TableCell>
                            <TableCell>
                                <div className={'totalCell'}>
                                    <b>{data.totalCost}€</b>
                                    <span className={'show-hide'} id={'toggleVisibility'} onClick={toggle}>
                                        <i className="fas fa-caret-up" />
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
        );
    };
}

export default PopmasterEventSummary;