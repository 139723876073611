import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

class EventLocation extends Component {

    suggestDestination = (destinationInput) => {
        if (destinationInput.target.value.length > 1) {
            let args = {componentRestrictions: {country: 'fi'}};
            let ac = new window.google.maps.places.Autocomplete(destinationInput.target, args);
            ac.addListener('place_changed', () => {
                let eventLocation = document.getElementById('eventLocation');
                // Check for address format Place, Street, City
                if (eventLocation.value.includes(',')) {
                    this.calculateDistance(eventLocation.value);
                    //this.setState({eventLocation: eventLocation.value});
                    this.props.callback({eventLocation: eventLocation.value});
                }
            });
        }
    };

    calculateDistance = (destination) => {
        if (destination.length < 3) {
            return;
        }

        const origins = [
            'Tampere, Finland',
            'Helsinki, Finland',
            'Turku, Finland',
            'Pori, Finland',
            'Jyväskylä, Finland',
            'Lahti, Finland',
            'Vaasa, Finland',
            'Oulu, Finland',
            'Kuopio, Finland'
        ];

        let service = new window.google.maps.DistanceMatrixService();
        service.getDistanceMatrix({
            origins: origins,
            destinations: [destination],
            travelMode: 'DRIVING'
        }, (res, status) => {

            if (status !== 'OK' || res.rows.length === 0) {
                return;
            }

            //console.log('res', res);

            let route = {distance: 0, origin: ''};
            // resolve shortest distance
            for(let i=0; res.rows.length > i; i++) {
                res.rows[i]['origin'] = res.originAddresses[i];
                let curDistance = res.rows[i]['elements'][0]['distance']['value'];
                if (route.distance === 0 || route.distance > curDistance) {
                    route.distance = curDistance;
                    route.origin = res.rows[i]['origin'];
                }
            }

            this.setState({route: route});
            this.props.callback({route: route});
            //console.log('route', route);
        });
    };

    render() {
        return (
            <FormControl className='formControl'>
                <TextField id='eventLocation'
                       label='Tapahtumapaikan nimi tai osoite'
                       onChange={this.suggestDestination}
                />
            </FormControl>
        )
    };
}

export default EventLocation;
