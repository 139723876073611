import React, {Component} from 'react';
import {TableBody} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import PopmasterEventSummaryMini from "./PopmasterEventSummaryMini";

class PopmasterCostBreakdown extends Component {

    render() {

        let data = this.props.data;

        let toggle = (event) => {
            const div = document.getElementsByClassName('cost-breakdown-summary');
            for(let i=0; i<div.length; i++) {
                div[i].classList.toggle('hidden');
            }

            const total = document.getElementsByClassName('total');
            for(let i=0; i<total.length; i++) {
                total[i].classList.toggle('hidden');
            }
        };

        const caretStyle = {
            float: 'right',
            paddingTop: '3px'
        };

        return (
            <div className={'cost-summary'}>
                <Table size={'small'} className={'total'}>
                    <TableBody>
                        <TableRow>
                            <TableCell><b>Kustannusarvio</b></TableCell>
                            <TableCell>
                                <b>{data.totalCost} €</b>
                                <span className={'show-hide'} id={'toggleVisibility'} onClick={toggle}>
                                    <i className="fas fa-caret-down" style={caretStyle} />
                                </span>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div className={'cost-breakdown-summary hidden'} id={'costBreakdownSummary'}>
                    <PopmasterEventSummaryMini data={data} />
                </div>
            </div>
        );
    };

}

export default PopmasterCostBreakdown;