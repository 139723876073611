import React, {Component} from "react";
//import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
//import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
//import MenuItem from "@material-ui/core/MenuItem";
import {Table} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

class PopmasterAdditionalGear extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gears: props.gears,
            availableGears: props.availableGears,
            contents: props.thePackage.contents
        };
    };

    handleChange = (event) => {
        //this.setState({gears: event.target.value});
        this.props.callback(event.target.value);
    };

    componentDidUpdate = (prevProps, prevState) => {
        //console.log('didUpdate additionalGear');
        //console.log('prevProps vs props');
        //console.log(prevProps);
        //console.log(this.props);
        //console.log('prevState vs state');
        //console.log(prevState);
        //console.log(this.state);
        //this.setState({merged: merged});
        //this.props.callback(merged);
    };

    componentDidMount() {

    };

    render() {
        //const names = this.props.availableGears;
        //const gears = this.props.gears;
        //const p = this.props.thePackage;
        const availableGears = this.props.gears;

        // Update allGears + count to parent

        let add = (event) => {
            this.props.addCb(event.target.dataset.id);
        };

        let deduct = (event) => {
            this.props.deductCb(event.target.dataset.id);
        };

        return (
            <Table id={'additionalGearTable'} className={'additionalGearTable'}>
                <TableBody>
                    {availableGears && availableGears.map((gear, key) => {
                        return <TableRow key={key}>
                                    <TableCell>
                                        {gear.title}
                                    </TableCell>
                                    <TableCell>
                                        <div className={'configurator'}>
                                            <i className={'fas fa-minus-circle'} onClick={deduct} data-id={gear.slug} />
                                            <Input disabled id={gear.slug} className={'grid-value'} value={gear.qty}/>
                                            <i className={'fas fa-plus-circle'} onClick={add} data-id={gear.slug} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                    })}
                </TableBody>
            </Table>
        );

        /*
        return (
            <FormControl className={'formControl'}>
                <InputLabel htmlFor="additionalGears">Lisävarusteet</InputLabel>
                <Select
                    multiple
                    value={gears}
                    onChange={this.handleChange}
                    name='additionalGears'
                    input={<Input id="additionalGears" />}
                >
                    {availableGears.map(gear => {
                        const theValue = JSON.stringify({
                            name: gear.title.rendered,
                            price: gear.price
                        });

                        return <MenuItem key={gear.id} value={theValue}>{gear.title.rendered}</MenuItem>
                    })}
                </Select>
            </FormControl>
        );
         */



    };
}

export default PopmasterAdditionalGear;