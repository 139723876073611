import React, {Component} from "react";
import {TableBody} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";

class PopmasterClientSummary extends Component {

    render() {
        let data = this.props.data;

        return (
            <div className={'client-summary'}>
                <h3>Asiakkaan tiedot</h3>
                <Table size={'small'}>
                    <TableBody>
                        {data.clientName &&
                        <TableRow>
                            <TableCell>Nimi</TableCell>
                            <TableCell>{data.clientName}</TableCell>
                        </TableRow>
                        }
                        {data.clientEmail &&
                        <TableRow>
                            <TableCell>Sähköposti</TableCell>
                            <TableCell>{data.clientEmail}</TableCell>
                        </TableRow>
                        }
                        {data.clientMobile &&
                        <TableRow>
                            <TableCell>Puhelin</TableCell>
                            <TableCell>{data.clientMobile}</TableCell>
                        </TableRow>
                        }
                        {data.clientInfo &&
                        <TableRow>
                            <TableCell>Lisätietoja</TableCell>
                            <TableCell>{data.clientInfo}</TableCell>
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
        );
    };

}

export default PopmasterClientSummary;